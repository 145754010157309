
/* ########## COULEURS ########## */

$bleu-marine: #32475c;
$gray-light: #ebebeb;
$orfonce: #dcc346;
$ormoyen: #efe57e;
$orclair: #efe37c;
$gris:#878787;


body{
	color: $bleu-marine;
	font-family: 'Lato',sans-serif;
}

a{
	color: $orfonce;
	&:hover{
		text-decoration: none;
		color: $orfonce;
	}
}








/* ########## HEADER ########## */

#header {
    background: white;

    .logo {
    	@media (min-width: 768px){
	    	//width: 130px;
		}
	}

	a{
		&:hover{
			text-decoration: none;
			color: $orfonce !important;
		}
	}
	
	

	.language-selector:hover{
		text-decoration: none;
		color: $orfonce !important;
	}

    .header-nav {
    	margin-bottom: 30px;
    	border:none;

    	.cart-preview.active {
    		background: none;
    		a{
    			color: $bleu-marine;
    		}
    		i{
				color: $orclair;
			}
		}

		.blockcart {
			background: none;
		}

		.user-info{
			margin-left: 1em;
			i{
				color: $orclair;
			}
		}

		.search-widget form{
			margin-top: 12px;
    		margin-right: 10px;

			input[type=text] {
			    @extend .contour-gris;
			    box-sizing: border-box;
    			width: 100%;
    			min-width: 100px;
    			padding: 3px;
			}
			.material-icons {
				font-size: 16px;
			}
			button[type=submit] {
			    bottom: .4rem;
			}
		}
	}

	

    .header-top{
    	background: white;

    	@media (min-width: 768px){
    		padding-bottom: 1.0rem;
    	}

		#_desktop_logo{
		    margin-top: -60px;
		}		

    	.top-menu{    
    		font-size: 13.5px;		
			.current a[data-depth="0"]{
			   color: $orfonce;
			}
			.sub-menu{
				background: white !important;
				@extend .contour-gris;				
			}

			a[data-depth="0"]{
			    color: $bleu-marine;
			    font-weight: normal;
			}

			a.dropdown-submenu{
				font-weight: 400;
				color: $bleu-marine;
				&:hover{
					color: $orfonce;
				}
			}
		}

		#_desktop_top_menu{
			.top-menu{    	
	    		a[data-depth="0"]{
				    color: $bleu-marine;
				    font-weight: normal;
				}
			}
		}

		.titrepro{
			padding: 0 .625rem 0;
			position: fixed;
		    top: 0;
		    left: 0;
		    z-index: 9999;
		    width: 100%;
		    border-radius: 0;
		    padding: 5px 10px;
		    vertical-align: middle;
		    margin: 0;
		    background-color: #dcc346;
		    color: white;
		    font-weight: bold;
		    text-align: center;
		}

		#_desktop_top_menu_pro{

			.top-menu{    	
	    		a[data-depth="0"]{

				}
			}

		}

	

		.col-md-10{
			@media (min-width: 768px){
			 	margin-top: 30px;
			}
		}
		.menu{
		    margin-top: 1rem;   
		    margin-bottom: 0;
		    padding-left: 5px;
		    width: 100%;
		}

		.oo-top-menu{
			display: none;
		}

		.oo-top-menu:first-child {
		    display: block;
		}
    }
}


#wrapper {
    background: white;
    box-shadow: none;
    padding-top: 0;

    .breadcrumb li:after {
	    content: ">";
	    color: $bleu-marine;
	}

	a{
		&:hover{
			text-decoration: none;
			//color: $orfonce;
		}
	}
}

#carousel{
	box-shadow: none;
	.carousel-inner {
    	height: 500px;
	}
	display: block !important;
	@media (max-width: 767px){
		.carousel-inner {
    		height: auto;
		}
	}
	.carousel-control {
    .icon-next,
    .icon-prev {
      &:hover {
        i {
          color: $orfonce;
        }
      }
    }
  }
}








/* ########## COMPOSANTS ########## */

.btn-primary, .btn-secondary, .btn-tertiary{
	box-shadow: none;	
}

.btn-primary {
	background-color: $bleu-marine;
	&.focus, &:focus, &:hover{
		background-color: $orfonce;
	}
}

.dropdown{
	&:hover .expand-more{
		color: $orfonce;
	}
}








/* ########## FOOTER ########## */

.footer-container {
    box-shadow: none;
    li a{
	    color: $bleu-marine;
	}
    li a:hover{
	    color: $orfonce;
	}
}

#footer{
    padding-top: 0.5rem;
	.links{
		text-align: center;
	}
	.col-md-12 p{
		text-align: center;
		.copyright{
			color: $gris;
		}
	}

	#social-networks{
		text-align: center;
		li{
			height: 2.5rem;
		    width: 2.5rem;
		    border-radius: 50%;
		    display: inline-block;
		    background-color: #fff;
		    background-repeat: no-repeat;
		    background-size: 2rem 2rem;
		    background-position: .25rem .25rem;
		    cursor: pointer;		    
		}
		a{
		    color: transparent;
		    text-shadow: none;
		    background-color: transparent;
		    border: 0;
	    }		
	}

	


	#block-reassurance{
		box-shadow: none;
		border: none;
		text-align: center;
		.col-md-1{			
			img{
				margin-right: 0;
			}
		}
		img{
			opacity: 1;
		}
		.col-md-3{
		    margin-bottom: 15px;
		}
	}

	@media (min-width: 768px){
		#block-reassurance{
			.col-md-1{
				text-align: right;
			}
			.col-md-3{
				text-align: left;
				margin-bottom: 0;
			}
		}
	}


}

.instagram2.icon-gray{
	background-image: url(../css/instagram_obiobi.svg);
}

.pinterest2.icon-gray {
    background-image: url(../css/pinterest_obiobi.svg);
}

.facebook2.icon-gray {
    background-image: url(../css/facebook_obiobi.svg);
}








/* ########## PAGE D'ACCUEIL ########## */

.carousel{
	margin-bottom: 0.5rem;
}

#instaTitre{
	text-align: center;
	margin-bottom: 10px;
	a{
		color: $bleu-marine;
	}
}

.owl-nav{
	display: none;
}

#index #instaTitre, #index .products-section-title{
	font-size: 1.3em;
    font-weight: bold;
    margin-top: 1.8em;
    margin-bottom: 1.8rem;
}

.instaText{
	color: #878787;
	text-align: center;
}

.products-section-title{	
}

#content > .homepageobi ~ .homepageobi {
    display: none;
}

#custom-text {
    margin-bottom: 0rem;
    padding: 2.5rem;
}








/* ########## PAGE MON COMPTE ########## */

.page-my-account #content{
		@extend .contour-gris;
	.links a span.link-item{
		@extend .contour-gris;
	}
}
    







/* ########## TITRES ########## */

.h1, h1 {
	font-weight: normal;
	text-transform: none;	
}

.block-category {
    min-height: initial;
    .h1, h1 {
		text-align: center;
		margin-bottom: 1rem;
	}
}









/* ########## BOX SHADOW ########## */

.contour-gris {
    border: 1px solid $gray-light;
	box-shadow: none;
	border-radius: 0;
	background: none;
}

.contour-blanc {
    border: 1px solid white;
	box-shadow: none;
	border-radius: 0;
}








/* ########## PANIER ########## */

.shopping-cart{
	background-image: url(../css/cart-obiobi-icon-jaune.svg);
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.product-add-to-cart .shopping-cart{
	background-image: url(../css/cart-obiobi-icon-blanc.svg);
}









/* ########## PAGE CATERGORIE ########## */

.block-category{
	&.card-block {
	    padding: 0;
	}

	padding-bottom: 0;
	.category-cover{
		position: relative;
	    text-align: center;
    	right: 0;
		img {
		    width: 100%;
		    height: auto;
		}
	}
	border: none;
	box-shadow: none;
	border-radius: 0;
	background: none;
}

.cart-container, .cart-summary{
    @extend .contour-gris;
}

#index .featured-products .thumbnail-container{
 	margin-bottom: 0;
 	height: 310px;
 	.product-description{
		height: 70px !important;
	}
}



#products, .featured-products, .product-accessories, .product-miniature{
	.thumbnail-container{
		height: 350px;
	    @extend .contour-blanc;
	    .product-description{
			box-shadow: none;
			height: 100px !important;
		}
	}

	.thumbnail-container:hover {
		@extend .contour-gris;
		.product-description{
			box-shadow: none;
			background: none;
		}
	}

	.product-title a{
		color: $bleu-marine;
		font-size: 1rem;
	}

	.product-price-and-shipping .price{
		font-size: 1rem;
	    font-weight: normal;
	    color: $orfonce;
	}
	.page-not-found {
		@extend .contour-gris;
	}

	.addToCartForm{
		margin-top: 10px;
	    position: absolute;
	    right: 30px;
	    bottom: 0px;
		.addToCartButtonBox{
			box-shadow: none !important;
			background: none !important;
			margin: 0;
	    	height: initial;
	    	width: initial !important;
	    	color: $orclair !important;


		}
	}

}

.products.row{
	margin-left: 0px;
}

#js-product-list-top, #js-active-search-filters{
	display: none;
}

.pagination{
	display: none;
}

.page-customer-account #content{
	@extend .contour-gris;
}








/* ########## PAGE PRODUIT ########## */

#product .tabs{
	@extend .contour-gris;
}

.product-cover img{
	box-shadow: none;
	.layer{
		opacity: 0 !important;
		&:hover {
	      opacity: 0;
	    }
	}
}

.product-price{
	color: $bleu-marine;
	font-weight: normal;
    font-size: 1.3rem;
    &.pull-xs-right{
    	font-size: 1rem;
    }
}

.product-images > li.thumb-container > .thumb {
	@extend .contour-gris;
}

.product-images > li.thumb-container > .thumb.selected, 
.product-images > li.thumb-container > .thumb:hover{
	border: 1px solid $bleu-marine;
}

.tabs{
	.nav-tabs, .nav-item {
		.nav-link.active, .nav-link:hover, .nav-link.active:hover{
			border-bottom: 3px solid $bleu-marine;
			color: $bleu-marine;
		}
	}
}

#blockcart-modal {
	.product-name{
		color: $bleu-marine;
	}

	.modal-header {
	    background: $orfonce;
	}

	.modal-body{
		background: none;
	}
}

.social-sharing li {
	box-shadow: none;
}

.product-availability{

}









/* ########## PAGE PANIER ########## */

.product-line-grid-right {
	.product-price {
		font-weight: normal;
	    font-size: 1rem;
	    strong{
	    	font-weight: normal;
	    }
	}

	.bootstrap-touchspin {
	    @extend .contour-gris;
	}

	a{
		&:hover{
			text-decoration: none;
			color: $orfonce;
		}
	}
}

.page-addresses .address, .page-authentication #content{
	@extend .contour-gris;
}








/* ########## PAGE CHECKOUT ########## */

body#checkout section.checkout-step, .js-cart{
	@extend .contour-gris;
}

.delivery-option-2{
	img{
		max-width: 50px;
	}
}







/* ########## PAGE CONFIRMATION DE COMMANDE ########## */

#order-confirmation .card{
	@extend .contour-gris;
}






/* ########## PAGE CONTACT ########## */

.contact-form{
	border: none;
	box-shadow: none;
}